import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { mode: "ios" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createTextVNode("No favourite " + _toDisplayString(_ctx.title) + ". ", 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}