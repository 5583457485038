
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonToolbar,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSearchbar,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRippleEffect,
  IonSkeletonText,
  IonThumbnail,
  IonImg,
} from '@ionic/vue';
import { addCircleSharp, trashOutline } from 'ionicons/icons';
import HeaderMain from '@/components/shared/HeaderMain.vue';
import { useRouter } from 'vue-router';
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Speaker } from '@/types/interface';
import { openToast } from '@/helpers/toast';
import bizvento from '@/services/bizvento';
import EmptyState from '@/components/shared/EmptyState.vue';

export default defineComponent({
  name: 'Speakers',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    HeaderMain,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonSearchbar,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonRippleEffect,
    EmptyState,
    IonSkeletonText,
    IonThumbnail,
    IonImg,
  },
  setup() {
    const store = useStore();
    const search = ref<string>('');
    const speakersSegmentVal = ref<string>('all');
    const fallbackImage = '../assets/fallback/speaker.png';

    const speakers = computed(() => {
      return (
        (store.state.speakers as Speaker[]).sort((a: Speaker, b: Speaker) =>
          a.last_name.localeCompare(b.last_name)
        ) || []
      );
    });

    const searchSpeakers = computed(() => {
      return speakers.value.filter((speaker) => {
        return (
          speaker.first_name
            .toLowerCase()
            .match(new RegExp(search.value, 'i')) ||
          speaker.last_name.toLowerCase().match(new RegExp(search.value, 'i'))
        );
      });
    });

    const favouriteSpeakers = computed((): Speaker[] => {
      const favouriteIds = store.state.favouriteSpeakers.map(
        (item: Speaker) => item.id
      );
      return searchSpeakers.value.filter(
        (item: Speaker) => favouriteIds.indexOf(item.id) > -1
      );
    });

    const addToFavourite = async (speaker: Speaker) => {
      const inFavourite = favouriteSpeakers.value.find(
        (fav) => fav.id === speaker.id
      );
      try {
        if (!inFavourite) {
          store.commit('SET_FAVOURITE_SPEAKERS', speaker);
          openToast(
            'Speaker has been added to favourites list',
            2000,
            'bottom',
            'success'
          );
          await bizvento.user.createFavourite(
            process.env.VUE_APP_EVENT_ID,
            store.state.user.uid,
            String(speaker.id),
            'speaker'
          );
        } else {
          openToast(
            'Speaker already in favourite tab',
            2000,
            'bottom',
            'danger'
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const removeFromFavourite = async (speaker: Speaker): Promise<void> => {
      try {
        store.commit('REMOVE_FAVOURITE_SPEAKER', speaker);
        openToast(
          'Speaker has been removed from favourites list',
          2000,
          'bottom',
          'danger'
        );
        await bizvento.user.deleteFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(speaker.id)
        );
      } catch (error) {
        console.log(error);
      }
    };

    const showError = function () {
      console.log('Image not found.');
    };

    const router = useRouter();

    return {
      addToFavourite,
      removeFromFavourite,
      favouriteSpeakers,
      searchSpeakers,
      search,
      speakersSegmentVal,
      addCircleSharp,
      trashOutline,
      router,
      speakers,
      fallbackImage,
      showError,
    };
  },
});
