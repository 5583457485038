
import { defineComponent } from 'vue';
import { IonCard, IonCardContent } from '@ionic/vue';

export default defineComponent({
  components: { IonCard, IonCardContent },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
